<template>
	<div><small>{{ message }}</small></div>
</template>

<script type="text/javascript"></script>

<script>

	import axios from 'axios'

	export default{
		name:'apitoken',
		data(){
			return{
				message:"Loading..."
			}
		},
		mounted() { 


			let error = this.$route.query.error


			if( error != undefined ){
				window.opener.openeralert("")
				return false
			}


			let loading = this.$route.query.loading
			let redirto = this.$route.query.redirto

			if( loading != undefined ){
				//alert(loading)
				location.href = decodeURIComponent(loading) + redirto
				return false
			}	


			console.log("code received")

			message:"Validating..."

			let state = this.$route.query.state
			let code = this.$route.query.code
			//let state = this.$route.query.state

			//alert(state)
			///alert(code)

			if( !localStorage.jiratoken ){

				let localparams = {"grant_type": "authorization_code","client_id": "jFmKBoctdmYmG0i3pbC9LYdiH5RJku8A","client_secret": "ATOAXGoWhX1pjesgC0-bRRqQqzJM5mavWG6l1waZENGI0XaPSFeuWLVyjjbfBw9WWndt24C095FC","code": code,"redirect_uri": "http://localhost:8080/apitoken"}

				let dev2params = {"grant_type": "authorization_code","client_id": "OrTRI9oWH962QAmvpccB3Y31aCvv8gFk","client_secret": "ATOAEEp2DPjDLVTnxYR71SMPQCq5DPtqKJMha8DqomgJ0UN-2UMFyCR7kU-XkIhlxdAmC6AC05A9","code": code,"redirect_uri": "https://dev2.flowe.io/apitoken"}


				let params = localparams

				let baseurl = window.location.href
				let isdev2 = baseurl.includes("dev2");

				if( isdev2 ){
					params = dev2params
				}

				console.log("getting auth")


				axios.post(  'https://auth.atlassian.com/oauth/token', params, { headers: { 'content-type': 'application/json' } } ).then( function( response ){

					let jirakey = null

					if( response.data ){

						if( response.data.access_token ){
							localStorage.jiratoken = response.data.access_token
							jirakey = response.data.access_token
							console.log("token received")
						}

						//console.log( response.data.access_token, "zzzzzzzz" )
						//let params = {}
						let headers = { headers: { Authorization: `Bearer ${response.data.access_token}` } }

						axios.get( "https://api.atlassian.com/oauth/token/accessible-resources", headers  ).then( function( response ){
	           				
	           				localStorage.jiraprofile = JSON.stringify(response.data)

	           				console.log("permissions recived a")

	           				//= response.data.access_token
							jirakey = localStorage.jiratoken 

	           				//let requestingurl = localStorage.requestingurl

		       				//if( !requestingurl ){
		       				//	requestingurl = "/"
		       				//}

	           				///location.href = requestingurl

	           				window.opener.openeralert( jirakey )
	       		 		})
					}

					
				})


			}else{

				//let jirakey = null

				let jiratoken = localStorage.jiratoken
				let headers = { headers: { Authorization: `Bearer ${jiratoken}` } }

				axios.get( "https://api.atlassian.com/oauth/token/accessible-resources", headers  ).then( function( response ){
       				//console.log( response.data, "jiratoken")
       				
       				//let requestingurl = localStorage.requestingurl

       				//if( !requestingurl ){
       				//	requestingurl = "/"
       				//}

       				console.log("permissions recived b")

       				localStorage.jiraprofile =  JSON.stringify(response.data)
	           		///location.href = requestingurl

	           		window.opener.openeralert( jiratoken )


   		 		}).catch( function(){
   		 			//localStorage.jiratoken = ""
   		 		})

			}

			

			/*axios.get( "members/me/boards" ).then( function( data ){
           		console.log( data, "boards")
       		 })*/
		}


	}
</script>